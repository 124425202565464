import axios from 'axios';

export const changeBiddingStatus = async (clientIds, isStopBidding, dataCenter) => {
  try {
    const {data} = await axios.put(`/v1/whitelabel/bidding`, {clientIds, isStopBidding, dataCenter});
    if (data === 'OK') {
      window.location.href = '/dashboard';
    }
  } catch (err) {
    console.error(`Error changeBiddingStatus`);
  }
};
