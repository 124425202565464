import React from 'react';
//
import {DashboardIcon, PeopleIcon, BarChartIcon, CurrencyExchangeIcon} from 'components/common/icons';

export const menu = [
  {
    icon: <DashboardIcon />,
    title: 'Home',
    link: '/dashboard',
    accessRoles: ['ADMIN'],
    items: [],
  },
  {
    icon: <PeopleIcon />,
    title: 'Publishers',
    link: '/publishers',
    accessRoles: ['ADMIN', 'MANAGER'],
    items: [
      {
        icon: null,
        title: 'Publishers',
        link: '/publishers',
        items: [],
      },
      {
        icon: null,
        title: 'Endpoints',
        link: '/endpoints',
        items: [],
      },
    ],
  },
  {
    icon: <BarChartIcon />,
    title: 'Reports',
    link: '/reports',
    accessRoles: ['ADMIN', 'MANAGER'],
    items: [],
  },
  {
    icon: <CurrencyExchangeIcon />,
    title: 'Transactions',
    link: '/transactions/publishers',
    accessRoles: ['ADMIN', 'MANAGER'],
    items: [
      {
        icon: null,
        title: 'Clients',
        link: '/transactions/clients',
        items: [],
      },
      {
        icon: null,
        title: 'Publishers',
        link: '/transactions/publishers',
        items: [],
      },
      {
        icon: null,
        title: 'Top Up Requests',
        link: '/transactions/top-up-requests',
        items: [],
      },
    ],
  },
];
