import React from 'react';
import ReactCountryFlag from 'react-country-flag';
//
import {alpha2CodeMap} from '../../../../constants/alpha2';
import numberFormat from '../../renderers/numberFormat';

export const REPORT_COLUMN_DEFS = [
    {
        id: 'groupBy',
        header: '',
        columns: [
            {
                id: 'createdDate',
                accessorKey: 'createdDate',
                header: 'Date',
                enableResizing: true,
            },
            {
                id: 'wlid',
                accessorKey: 'whLabelUser',
                header: 'Client',
                enableResizing: true,
            },
            {
                id: 'uPublisherId',
                accessorKey: 'uPublisher',
                header: 'Endpoint',
                enableResizing: true,
            },
            {
                id: 'geo',
                accessorKey: 'geo',
                header: 'Geo',
                size: 100,
                Cell: ({cell}) => {
                    if (!cell.getValue()) return null;

                    const country = alpha2CodeMap.hasOwnProperty(cell.getValue()) ? alpha2CodeMap[cell.getValue()] : {};

                    return (
                      <span>
                        <ReactCountryFlag
                          className='emojiFlag'
                          countryCode={cell.getValue()}
                          style={{
                            fontSize: '2em',
                            lineHeight: '2em',
                          }}
                          title={country.label}
                        />
                        {` ` + cell.getValue()}
                      </span>

                    );
                },
            },
        ],
    },
    {
        id: 'base',
        header: '',
        columns: [
            {
                accessorKey: 'responses',
                header: 'Responses',
                enableResizing: true,
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('responses')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'revenue',
                header: 'Revenue',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('revenue')), 0);

                    return numberFormat(total);
                },
            },
        ],
    },
    {
        id: 'impressions',
        header: 'Impressions',
        columns: [
            {
                accessorKey: 'impressions',
                header: 'Total',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('impressions')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'approvedImpressions',
                header: 'Approved',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('approvedImpressions')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'percentApprovedImpressions',
                header: '%',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
            },
            {
                accessorKey: 'winRate',
                header: 'Win Rate (%)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
            },
        ],
    },
    {
        id: 'clicks',
        header: 'Clicks',
        columns: [
            {
                accessorKey: 'clicks',
                header: 'Total',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('clicks')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'approvedClicks',
                header: 'Approved',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('approvedClicks')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'percentApprovedClicks',
                header: '%',
                Cell: ({cell}) => numberFormat(cell.getValue()),
                enableResizing: true,
            },
            {
                accessorKey: 'ctr',
                header: 'CTR (%)',
                Cell: ({cell}) => numberFormat(cell.getValue()),
                enableResizing: true,
            },
        ],
    },
    {
        id: 'pixels',
        header: 'Pixels',
        columns: [
            {
                accessorKey: 'visitPixelsCount',
                header: 'Visit',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('visitPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaVisitPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaVisitPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'lpViewPixelsCount',
                header: 'LP View',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('lpViewPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaLpViewPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaLpViewPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'lpClickPixelsCount',
                header: 'LP Click',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('lpClickPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaLpClickPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaLpClickPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'regStartedPixelsCount',
                header: 'RegStart',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('regStartedPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaRegStartedPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaRegStartedPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'regFinishedPixelsCount',
                header: 'RegFin',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('regFinishedPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaRegFinishedPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaRegFinishedPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'loginPixelsCount',
                header: 'Login',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('loginPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaLoginPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaLoginPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'ftdPixelsCount',
                header: 'FTD',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('ftdPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'ftdPixelsAmount',
                header: 'FTD Amount ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('ftdPixelsAmount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaFtdPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaFtdPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'depositPixelsCount',
                header: 'Deposit',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('depositPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'depositPixelsAmount',
                header: 'Deposit Amount ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('depositPixelsAmount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaDepositPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaDepositPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'betPixelsCount',
                header: 'Bet',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('betPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaBetPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaBetPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'withdrawalPixelsCount',
                header: 'Withdrawal',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('withdrawalPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaWithdrawalPixels',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaWithdrawalPixels')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'otherPixelsCount',
                header: 'Other',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('otherPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
            {
                accessorKey: 'cpaOtherPixelsCount',
                header: 'CPA ($)',
                enableResizing: true,
                Cell: ({cell}) => numberFormat(cell.getValue()),
                Footer: ({table}) => {
                    const total = table
                        .getFilteredRowModel()
                        .rows.reduce((sum, row) => sum + Number(row.getValue('cpaOtherPixelsCount')), 0);

                    return numberFormat(total);
                },
            },
        ],
    },
    {
        id: 'moneyStats',
        header: '',
        columns: [
            {
                accessorKey: 'eCPM',
                header: 'eCPM ($)',
                Cell: ({cell}) => numberFormat(cell.getValue()),
                enableResizing: true,
            },
            {
                accessorKey: 'eCPC',
                header: 'eCPC ($)',
                Cell: ({cell}) => numberFormat(cell.getValue()),
                enableResizing: true,
            },
            {
                accessorKey: 'bidPrice',
                header: 'Bid price ($)',
                Cell: ({cell}) => numberFormat(cell.getValue()),
                enableResizing: true,
            },
        ],
    },
];
