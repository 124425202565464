import axios from 'axios';
import reactCookies from 'react-cookies';
import jwtDecode from 'jwt-decode';
import querystring from 'querystring';

import requestToken from 'utils/requestToken';
import {showSuccessNotification} from 'utils/common';

import config from '../config';

axios.defaults.baseURL = config.REST_API;

export const getWhiteLabels = async () => {
  const {data} = await axios.get('/v1/whitelabel/get');
  return data;
};

export const createWhiteLabel = async (obj) => {
  const {data} = await axios.post('/v1/whitelabel/add', obj);
  return data;
};

export const updateWhiteLabel = async (obj) => {
  const {data} = await axios.post(`/v1/whitelabel/update/${obj.id}`, obj);
  return data;
};

export const getPublishers = async () => {
  const {data} = await axios.get('/v1/publisher');
  return data;
};

export const getEndpoints = async () => {
  const {data} = await axios.get('/v1/publisher/endpoints');
  return data;
};

export const getPublisherByID = async (id) => {
  const {data} = await axios.get(`/v1/publisher/${id}`);
  return data;
};

export const getEndpointByID = async (id) => {
  const {data} = await axios.get(`/v1/publisher/endpoint/${id}`);
  return data;
};

export const createPublisher = async (id, obj) => {
  const {data} = await axios.post('/v1/publisher/add', obj);
  return data;
};

export const updatePublisher = async (id, obj) => {
  const {data} = await axios.post(`/v1/publisher/update/${id}`, obj);
  return data;
};

export const createEndpoint = async (obj) => {
  const {data} = await axios.post('/v1/publisher/endpoint/add', obj);
  return data;
};

export const updateEndpoint = async (obj) => {
  const {data} = await axios.post(`/v1/publisher/endpoint/update/${obj.id}`, obj);
  return data;
};

export const updatePublisherStatus = async (obj) => {
  const {data} = await axios.post(`/v1/publisher/update-status`, obj);
  return data;
};

export const updateEndpointStatus = async (obj) => {
  const {data} = await axios.post(`/v1/publisher/endpoint/update-status`, obj);
  return data;
};

export const signIn = (data) => {
  return axios.post('/v1/auth/sign-in', data)
      .then((response) => {
        const {data: {token, user}} = response;
        requestToken.set(token);
        reactCookies.save('token', token, {path: '/'});

        window.location.href = user.role === 'ADMIN' ? '/dashboard' : '/publishers';
      });
};

export const signInViaToken = async () => {
  try {
    if (reactCookies.load('token')) {
      const token = reactCookies.load('token');
      requestToken.set(token);

      const userId = jwtDecode(token).sub;
      const role = jwtDecode(token).role;
      const loginAs = !!jwtDecode(token).idAdmin;

      const {data} = await axios.post('/v1/auth/get-user', {id: userId, role, loginAs});
      return data;
    }
  } catch (e) {
    console.error(e.message);
  }
};

export const logout = (history) => {
  requestToken.delete();
  reactCookies.remove('token', {path: '/'});
  history.push('/login');
};

export const getGeneralByID = async (id) => {
  const {data} = await axios.get(`/v1/whitelabel/general-info/${id}`);
  return data;
};

export const getGatewayBudgetByID = async (id) => {
  const {data} = await axios.get(`/v1/whitelabel/gateway-budget/${id}`);
  return data;
};

export const updateWLGatewayBudget = async (id, formData) => {
  const {data} = await axios.post(`/v1/whitelabel/gateway-budget/${id}`, formData);
  return data;
};

export const getGatewayTransactionList = async (id) => {
  const {data} = await axios.get(`/v1/whitelabel/gateway-budget/${id}/transactions`);
  return data.transactions;
};

export const getClientTransactions = async (filters = {}) => {
  const apiUrl = `/v1/whitelabel/transactions?${querystring.stringify(filters)}`;

  try {
    const {data} = await axios.get(apiUrl);
    return data.transactions;
  } catch (err) {
    return {};
  }
};

export const getUgwPublisherEndpointTransactions = async (filters = {}) => {
  const apiUrl = `/v1/publisher/endpoint-transactions?${querystring.stringify(filters)}`;

  try {
    const {data} = await axios.get(apiUrl);
    return data.transactions;
  } catch (err) {
    return {};
  }
};

export const getPublisherPaymentTransactions = async (filters = {}) => {
  const apiUrl = `/v1/payment?${querystring.stringify(filters)}`;

  try {
    const {data} = await axios.get(apiUrl);
    return data;
  } catch (err) {
    return {};
  }
};

export const getPaymentDetails = async (paymentNumber) => {
  const apiUrl = `/v1/payment/${paymentNumber}`;

  try {
    const {data} = await axios.get(apiUrl);
    return data;
  } catch (err) {
    return {};
  }
};

export const createPayment = async (transactions) => {
  const {data} = await axios.post(`/v1/payment`, {transactions});
  return data;
};

export const approvePayment = async (payments) => {
  const {data} = await axios.post(`/v1/payment/approve`, {payments});
  return data;
};

export const completePayment = async (payments) => {
  const {data} = await axios.post(`/v1/payment/complete`, {payments});
  return data;
};

export const updateAppDomainByID = async (id, newData) => {
  const {data} = await axios.post(`/v1/whitelabel/app-info/${id}`, newData);
  return data;
};

export const catchTraffRequest = async (id) => {
  await axios.get(`/v1/publisher/endpoint/catch-req/${id}`);
  showSuccessNotification('Success!');
};

export const catchTraffResponse = async (id) => {
  await axios.get(`/v1/publisher/endpoint/catch-resp/${id}`);
  showSuccessNotification('Success!');
};
