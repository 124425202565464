import * as React from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {CssBaseline} from '@material-ui/core';
import General from './tabs/General';
import Subscription from './tabs/Subscription';
import Gateway from './tabs/Gateway';


export default function EditPage(props) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{paddingTop: 64, margin: 'auto', maxWidth: '1200px'}}>
      <CssBaseline />
      <br />
      <Box sx={{width: '100%', typography: 'body1'}}>
        <TabContext value={value}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleChange} aria-label='tabs'>
              <Tab label='General' value='1' />
              <Tab label='Subscription' value='2' disabled={!props.isUpdate} />
              <Tab label='Gateway' value='3' disabled={!props.isUpdate} />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <General isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='2'>
            <Subscription isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='3'>
            <Gateway isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
