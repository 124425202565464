import React from 'react';
import clsx from 'clsx';
import {Link, Tooltip} from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArticleIcon from '@mui/icons-material/Article';
import {catchTraffRequest, catchTraffResponse} from 'actions/index';

import config from '../../config';

export const getColumns = (props) => [
  {
    field: 'id',
    headerName: 'Name',
    sortable: true,
    disableClickEventBubbling: true,
    renderCell: ({row}) => {
      const displayText = `(${row.id}) ${row.name}`;
      return (
        <Tooltip title={displayText} placement='top'>
          <Link style={{
            cursor: 'pointer',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          >
            {displayText}
          </Link>
        </Tooltip>
);
    },
    sortComparator: (v1, v2) => v1 - v2,
    width: 300,
  },
  {field: 'adType', headerName: 'Ad Type', sortable: true, width: 100},
  {
    field: 'status',
    headerName: 'Status',
    disableClickEventBubbling: true,
    renderCell: (params) => {
      const classStatus = clsx('status', {
        new: params.value.toLowerCase() === 'new',
        active: params.value.toLowerCase() === 'active',
        paused: params.value.toLowerCase() === 'paused',
        pending: params.value.toLowerCase() === 'pending',
        removed: params.value.toLowerCase() === 'removed',
      });

      return (
        <span className={classStatus}>{params.value}</span>
      );
    },
    width: 100,
  },
  {
    field: 'hourlySpend',
    headerName: 'Hourly Spend',
    sortable: true,
    width: 110,
    renderCell: ({row}) => {
      if (!row.hourlyBudget) {
        return null;
      }

      const spendPercentage = row.hourlySpendPercentage;

      const balanceSpendClassName = clsx('balance-spend-status', {
        low: spendPercentage < 50,
        half: spendPercentage >= 50 && spendPercentage < 75,
        high: spendPercentage >= 75,
      });

      return (
        <span className={balanceSpendClassName}>{row.hourlySpend} / {row.hourlyBudget}</span>
      );
    },
  },
  {
    field: 'dailySpend',
    headerName: 'Daily Spend',
    sortable: true,
    width: 100,
    renderCell: ({row}) => {
      if (!row.dailyBudget) {
        return null;
      }

      const spendPercentage = row.dailySpendPercentage;

      const balanceSpendClassName = clsx('balance-spend-status', {
        low: spendPercentage < 50,
        half: spendPercentage >= 50 && spendPercentage < 75,
        high: spendPercentage >= 75,
      });

      return (
        <span className={balanceSpendClassName}>{row.dailySpend} / {row.dailyBudget}</span>
      );
    },
  },
  // {
  //   field: 'totalSpend',
  //   headerName: 'Total Spend',
  //   sortable: true,
  //   renderCell: (params) => {
  //     const spendPercentage = 25;
  //
  //     const balanceSpendClassName = clsx('balance-spend-status', {
  //       low: spendPercentage < 50,
  //       half: spendPercentage >= 50 && spendPercentage < 75,
  //       high: spendPercentage >= 75,
  //     });
  //
  //     return (
  //         <span className={balanceSpendClassName}>250 / 1000</span>
  //     );
  //   },
  // },
  {
    field: 'qpsThreshold',
    headerName: 'Max QPS',
    sortable: true,
  },
  {
    field: 'avgQps',
    headerName: 'Avg QPS',
    sortable: true,
  },
  {
    field: 'tqps',
    headerName: 'TQPS',
    sortable: true,
  },
  {
    field: 'qps',
    headerName: 'QPS',
    sortable: true,
  },
  {
    field: 'createdAt',
    headerName: 'Registered',
    valueFormatter: (params) => {
      const valueFormatted = params.value ? params.value.slice(0, 10) : params.value;
      return `${valueFormatted}`;
    },
    sortable: true,
    flex: 1,
  },
  {
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <span>
          <span style={{cursor: 'pointer', color: '#3f51b5'}}><PlayCircleIcon onClick={() => catchTraffResponse(params.id)} /></span>
          <span style={{cursor: 'pointer', color: '#3f51b5'}}><PlayCircleIcon onClick={() => catchTraffRequest(params.id)} /></span>
          <a href={`${config.REST_API}/endpoint-${params.id}.txt`} style={{color: '#3f51b5'}} target='_blank' rel='noreferrer'><ArticleIcon /></a>
        </span>
      );
    },
  },
];
