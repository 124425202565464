import React, {useEffect, useState} from 'react';
import {Box, Button} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {MaterialReactTable} from 'material-react-table';
//
import {REPORT_COLUMN_DEFS} from 'utils/tables/columns/reports/daily';

const filterColumnByAccessorKey = (reportDefs, id, accessorKey) => {
    return reportDefs.map((item) => {
        if (item.id === id) {
            return {
                ...item,
                columns: item.columns.filter((col) => col.id === accessorKey),
            };
        }
        return item;
    });
};

const ReportTable = (props) => {
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState(props.paginationModel);

    useEffect(() => {
        props.onPaginationChange(pagination);
    }, [pagination.pageIndex, pagination.pageSize]);


    useEffect(() => {
        const columnDefs = filterColumnByAccessorKey(REPORT_COLUMN_DEFS, 'groupBy', props.groupBy);

        setColumns(columnDefs);
    }, props.groupBy);


   return (
     <div style={{width: '100%', marginTop: '15px'}}>
       <MaterialReactTable
         columns={columns}
         data={props.rowData}
         rowCount={props.count}
         enableColumnResizing
         enableStickyHeader
         enableStickyFooter
         enableColumnActions={false}
         manualPagination
         muiTableBodyCellProps={{
           style: {whiteSpace: 'nowrap'},
         }}
         muiTablePaginationProps={{
             rowsPerPageOptions: [100, 200, 500],
         }}
         state={{
             isLoading: props.isRequestPending,
             pagination: props.paginationModel,
         }}
         renderTopToolbarCustomActions={({table}) => (
           <Box
             sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
           >
             <Button
               color='primary'
               onClick={props.onDownload}
               startIcon={<FileDownloadIcon />}
               variant='contained'
             >
               Export Data
             </Button>
           </Box>
         )}
         onPaginationChange={setPagination}
       />
     </div>
   );
};

export default ReportTable;
